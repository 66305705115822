
/**
 * `ko-grip` binding handler
 *
 * A generic grip and drag binding handler which delegates the details
 * to the caller. Used when you want to do something other than the list
 * item re-arraging provided by `ko-grip-area`.
 *
 * Note: This is not compatible with `ko-click`. Use the `onClick`
 * parameter if you need to specify a click handler. If will be called
 * for clicks that are not drag & drop events.
 *
 */

import tko from '@tko/build.reference/dist/build.reference.es6'

type GripAreaRef = any

interface GripEvent {
  event: MouseEvent,
  gripAreaRef: GripAreaRef // The value returned by `GripParams.getGripAreaRef()`
}

const PASSIVE = { passive: true }

interface GripParams {
  dragEvent?: KnockoutObservable<GripEvent>
  hoverEvent?: KnockoutObservable<GripEvent>
  getGripAreaRef?: (evt:MouseEvent) => GripAreaRef
  onDragStart?: (evt:GripEvent) => void
  onDragStop?: (evt:GripEvent) => void
  onDrag?: (evt:GripEvent) => void
  onClick?: (evt:MouseEvent) => void
}

export default class Grip extends tko.BindingHandler {
  value: GripParams = this.value
  $element: Element = this.$element
  ignoreNextClick: boolean = false
  hoverLeaveTimer: any = null
  pendingDrag: GripEvent = null
  handlers: any

  constructor (...args) {
    super(...args)
    if (!this.value) { return }
    Object.assign(this.value, {
      ...{
        dragEvent: ko.observable(null),
        isInGripArea: () => true,
      },
      ...this.value,
    })

    const handlers = this.handlers = {
      onMouseDown: evt => this.onMouseDown(evt),
      onMouseUp: evt => this.onMouseUp(evt),
      onMouseMove: evt => this.onMouseMove(evt),
      onMouseLeave: evt => this.onMouseLeave(evt),
      onClick: evt => this.onClick(evt),
    }

    this.addEventListener('mousedown', handlers.onMouseDown)
    this.addEventListener('mousemove', handlers.onMouseMove, PASSIVE)
    this.addEventListener(document.body, 'mouseup', handlers.onMouseUp)

    if (this.value.hoverEvent) {
      this.addEventListener('mouseleave', handlers.onMouseLeave, PASSIVE) }
    this.addEventListener('click', handlers.onClick)
    if (this.value.onDrag) {
      this.subscribe(this.value.dragEvent, this.value.onDrag) }
  }

  dispose (...args) {
    clearTimeout(this.hoverLeaveTimer)
    super.dispose(...args)
  }

  onMouseDown (event: MouseEvent) {
    if (event.button != 0) { return }
    this.pendingDrag = null
    const gripAreaRef = this.value.getGripAreaRef(event)
    if (!gripAreaRef) { return }
    event.preventDefault()
    event.stopPropagation()
    this.pendingDrag = { event, gripAreaRef }
  }

  onMouseUp (event) {
    this.pendingDrag = null
    const dragEvent = this.value.dragEvent()
    if (dragEvent) {
      if (this.value.onDragStop) {
        const { gripAreaRef } = dragEvent
        this.value.dragEvent({event, gripAreaRef})
        this.value.onDragStop(this.value.dragEvent())
      }
      this.value.dragEvent(null)
      this.$element.removeEventListener('mousemove', this.handlers.onMouseMove)
      this.addEventListener(document.body, 'mousemove', this.handlers.onMouseMove, PASSIVE)
    }
  }

  onMouseMove (event) {
    clearTimeout(this.hoverLeaveTimer)
    const gripEvent = this.value.dragEvent()

    if (gripEvent) {
      const { gripAreaRef } = gripEvent
      this.value.dragEvent({event, gripAreaRef})

    } else if (this.pendingDrag) {
      const { gripAreaRef } = this.pendingDrag
      const dragEvent = {event, gripAreaRef}
      if (this.value.onDragStart) { this.value.onDragStart(dragEvent) }
      this.value.dragEvent(dragEvent)
      this.ignoreNextClick = true
      this.pendingDrag = null
      this.$element.removeEventListener('mousemove', this.handlers.onMouseMove)
      this.addEventListener(document.body, 'mousemove', this.handlers.onMouseMove, PASSIVE)
    } else {
      const gripAreaRef = event.target === this.$element
        ? this.value.getGripAreaRef(event) : null
      if (this.value.hoverEvent) {
        this.value.hoverEvent(gripAreaRef ? { event, gripAreaRef } : null)
      }
    }
  }

  onMouseLeave (evt) {
    if (!this.value.dragEvent()) {
      this.hoverLeaveTimer = setTimeout(() => this.value.hoverEvent(null), 50)
    }
  }

  onClick (evt) {
    if (this.ignoreNextClick) {
      evt.preventDefault()
      evt.stopPropagation()
      this.ignoreNextClick = false
    } else {
      if (this.value.onClick) { return this.value.onClick(evt) }
    }
  }

  addEventListener (...args) { return super.addEventListener(...args) }
  subscribe (...args) { return super.subscribe(...args) }
}
