
export default function lockWhile<T> (obs: KnockoutObservable<T>, lock: KnockoutObservable<Boolean>) : KnockoutComputed<T> {
  let pendingValue = null
  lock.subscribe(locked => {
    if (!locked && pendingValue) {
      obs(pendingValue)
      pendingValue = null
    }
  })
  return ko.computed({
    read: obs,
    write: value => {
      if (lock()) {
        pendingValue = value
      } else {
        obs(value)
      }
    }
  })
}
