
const STATIC_ARRAY_PROPERTIES = [
  'length',
  'forEach',
  Symbol.iterator
]

const computableArrayProperties = [
  'indexOf',
  'lastIndexOf',
  'entries',
  'every',
  'find',
  'findIndex',
  'reduce',
  'reduceRight',
  'some'
]

const computableArrayToArray = [
  'slice',
  'filter',
  'keys',
  'map',
  'flat',
  'flatMap',
  'values'
]

/**
 * @target {Observable|object} Extend the observable, or extend
 *    ko.observableArray.fn.
 */
export default function arrayProperties (target) {
  const properties = {}
  for (const prop of computableArrayProperties) {
    properties[prop] = {
      value (...args) {
        return tko.pureComputed(() => this()[prop](...args))
      }
    }
  }
  for (const prop of computableArrayToArray) {
    properties[prop] = {
      value (...args) {
        return tko.pureComputed(() => this()[prop](...args))
          .extend({ arrayProperties: true })
      }
    }
  }
  Object.defineProperties(target, properties)
  if (typeof target === 'function') {
    target.extend({ proxy: STATIC_ARRAY_PROPERTIES })
  } else {
    Object.defineProperties(target, {
      forEach: { value (...args) { return target().forEach(...args) } }
    })
  }
}
