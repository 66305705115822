
import tko from '@tko/build.reference/dist/build.reference.es6'

const MODIFIERS = [
  'ctrl', 'alt', 'shift', 'meta'
]

abstract class KeyHandler extends tko.BindingHandler {
  abstract get eventName () : string

  constructor (...args) {
    super(...args)
    this.addListeners()
  }

  addListeners () {
    this.addEventListener(this.eventName, evt => this.handle(evt))
  }

  /**
   * Handle key mapping by the KeyboardEvent.key property e.g.:
   *  ko-{keydown,keyup,keypress}={
   *    Escape: escapeHandlerFn,
   *    Enter: enterHandlerFn,
   *    'shift+Enter': shiftEnterHandlerFn,
   *    debug: true
   * }
   * @param {mouseEvent} mouseEvt
   */
  handle (keyboardEvt) {
    const key = keyboardEvt.key
    const keysMap = tko.toJS(this.value)
    const modifiers = MODIFIERS.filter(m => keyboardEvt[m + 'Key'])
    const keyName = [...modifiers, key].join('+')

    if (keysMap.debug) {
      console.debug(`[${this.constructor.name} Debug]`,
        keyName, keysMap, keysMap[keyName])
    }

    if (typeof keysMap[keyName] === 'function') {
      return keysMap[keyName](keyboardEvt)
    }
  }
}

export class KeyDown extends KeyHandler {
  get eventName () { return 'keydown' }
}

export class KeyUp extends KeyHandler {
  get eventName () { return 'keyup' }
}

export class KeyPress extends KeyHandler {
  get eventName () { return 'keypress' }
}
