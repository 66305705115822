//
//   foreignKeyMap
//   ~~~~~~~~~~~~~
//
// Like foreignKey, adds .fkm property - a list of .fk (from foreignKey)
// objects.
//
// This whole operation could be dramatically sped up with some clever use of
// .extend(rateLimit: ...).    FIXME
//
// Also, for efficient array changes, see:
// http://blog.stevensanderson.com/2013/10/08/knockout-3-0-release-candidate-available/
// Sorting of projections would be of good use here, if and when such comes
// along.
//
// Expects ForeignKey to be a global.
//
const {eraro} = global

import {ForeignKey} from './foreignKeyExtender'

export class ForeignKeyMap {
  constructor (Model, key_list) {
    this.Model = Model
    this.key_list = key_list

    this.fks = this.key_list
      .map(function (key) { return new ForeignKey(key, Model) })

    this.models = ko.pureComputed(this.computeModels, this)
    this.is_map_loaded = ko.pureComputed(this.computeIsMapLoaded, this)

    // For disposal tracking
    this.subs = [this.models, this.fks, this.is_map_loaded]
  }

  computeModels () {
    return this.fks()
      .filter(fk => fk.is_loaded())
      .map(fk => fk.model())
  }

  computeIsMapLoaded () {
    return this.fks().every(fk => fk.is_loaded())
  }

  dispose () {
    const length = this.subs.length
    for (var i = 0; i < length; ++i) {
      this.subs[i].dispose()
    }
  }
}

export default function foreignKeyMap (target, Model) {
  if (target.fkm instanceof ForeignKeyMap) {
    return target
  }
  if (!ko.isObservableArray(target)) {
    eraro.raise(new Error('ForeignKeyMap received a no-observableArray.'))
  }
  target.fkm = new ForeignKeyMap(Model, target)
  return target
}

