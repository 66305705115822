import tko from '@tko/build.reference/dist/build.reference.es6'

export default class Collapse extends tko.BindingHandler {
  constructor (...args) {
    super(...args)
    if (this.value) {
      this.$element.style.height = '0px'
    }
  }

  onValueChange () {
    const node = this.$element
    if (this.value) {
      node.style.height = node.scrollHeight + 'px'
      setTimeout(() => node.style.height = '0px', 15)
    } else {
      if (node.scrollHeight && !node.clientHeight) {
        node.style.height = node.scrollHeight + 'px'
        node.addEventListener('transitionend', () => {
          node.style.height = 'auto'
        }, { once: true })
      }
    }
  }
}
