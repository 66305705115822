import tko from '@tko/build.reference/dist/build.reference.es6'

const targetMap = new WeakMap()
const threshold = Array.from({ length: 11 }, (_, i) =>  i * 0.1)

const observer = 'IntersectionObserver' in global && new IntersectionObserver(entries => {
  entries.forEach(entry => {
    const callable = targetMap.get(entry.target)
    if ( callable ) { callable(entry) }
  })
}, { threshold })

/**
 * Publishes the intersection observer entry when the target moves across the
 * viewport in 10% (of the target) increments.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
 *
 */
export default class Viewport extends tko.BindingHandler {
  // 🚨 Prefer the more generic `ko-intersection`, though this is
  // a more performant version b/c it has a single IntersectionObserver.
  constructor (...args) {
    super(...args)
    targetMap.set(this.$element, entry => this.value = entry)
    observer.observe(this.$element)
  }
  dispose (...args) {
    super.dispose(...args)
    observer.unobserve(this.$element)
  }
}
