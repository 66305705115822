import tko from '@tko/build.reference/dist/build.reference.es6'

import { elementIsBefore, elementsAreAdjacent } from 'utils/dom'
import { debounce } from 'utils/function'

const NORMAL_DEBOUNCE = 20
const LEFT_CLICK = 0

/**
 * The gripArea binds to a container of Elements that contain a [grip]
 * attribute.  This will usually be a list.
 *
 * When the [grip] item is dragged, it will be cloned and bound to document,
 * and the items will be moved.
 *
 * When the items are dropped, the value passed to the binding is called with
 * { from: index, to: index }.
 *
 * Usage:
 *
 *      <div class='list' ko-grip-area={onMove}>
 *         <div class='item'  [gripping]>
 *            item-content
 *            <i grip>GRIP</i>
 *         </div>
 *      </div>
 *
 * The `gripping` attribute is added to the item so it can be styled.
 *
 * A clone of the node will be created, with the attribute `[grip-clone]`
 *
 * Set the CSS variable `--grip-clone-z-index` to any z-index needed.
 */

export default class GripArea extends tko.BindingHandler {
  get Z_INDEX () { return 'var(--grip-clone-z-index)' }

  constructor (...args) {
    super(...args)

    if (!this.value) { return }

    if (typeof this.value === 'function') {
      this.onItemMove = this.value
    } else {
      const { onItemMove, overItem, cloneStyle, customDelta, noReset } = this.value
      if (!onItemMove) { return }
      Object.assign(this, { onItemMove, overItem, cloneStyle, customDelta, noReset })
    }

    Object.assign(this, {
      gripping: false,
      boundOnMouseMove: evt => this.onMouseMove(evt),
      debouncedMouseOver: debounce(evt => this.onMouseOver(evt), NORMAL_DEBOUNCE)
    })

    this.addEventListener(this.$element, 'mousemove', evt => this.onMouseMove(evt))
    this.addEventListener(this.$element, 'mousedown', evt => this.onMouseDown(evt))
    this.addEventListener(this.$element, 'mouseover', this.debouncedMouseOver)
    this.addEventListener(this.$element, 'click', evt => {
      if (this.gripping) {
        this.onStopGrip()
        evt.preventDefault()
        evt.stopPropagation()
      }
    })
    this.addEventListener(this.$element, 'mouseup',
      () => {
        clearTimeout(this._downTimeout)
        if (!this.gripping) { this.resetTempMarks() }
      })
  }

  onMouseDown (evt) {
    if (evt.button !== LEFT_CLICK) { return }
    if (!evt.target.closest('[grip]')) { return }
    this.markItemElements()
    const item = evt.target.closest('[grip-item]')
    if (!item || (item.hasAttribute('grip-selected') && (item.getAttribute('grip-selected') === 'false'))) { return }
    evt.stopPropagation()
    evt.preventDefault()
    this.gripElement = evt.target
    this.clickOffsetX = evt.offsetX
    this.clickOffsetY = evt.offsetY
    if (this._downTimeout) { clearTimeout(this._downTimeout) }
    this._downTimeout = setTimeout(() => {
      if (this._downTimeout) {
        this.startGrip(evt)
      }
      this._downTimeout = null
    }, 100)
  }

  /**
   * @param {Event} evt optional mouse event
   */
  startGrip (evt) {
    if (this.gripping) { return }
    if (!this.gripElement) { return }
    const grippedItemElement = this.gripElement.closest('[grip-item]')
    if (!grippedItemElement) { return }
    this.onStopGrip()
    this.gripping = true
    this.$element.setAttribute('grip-area-active', '')
    this.grippedItemElement = grippedItemElement
    this.itemBounds = grippedItemElement.getBoundingClientRect()
    grippedItemElement.setAttribute('gripping', '')
    grippedItemElement.style.visibility = 'hidden' // Disables mouse over events
    if (grippedItemElement.getAttribute('grip-selected')) {
      this.$element
        .querySelectorAll('[grip-item][grip-selected=true]')
        .forEach(e => e.setAttribute('gripping', ''))
    }
    this.addClone(grippedItemElement)
    if (evt) { this.moveClone(evt) }
  }

  /**
   * Make it easy to find the `item` element that contains a given item
   */
  markItemElements () {
    if (this.$element.querySelector('[grip-item]')) {
      this._tempGripAttribs = false
      return
    }
    let i = 0
    for (const child of this.$element.children) {
      child.setAttribute('grip-item', `${i++}`)
    }
    this._tempGripAttribs = true
  }

  resetTempMarks () {
    if (!this._tempGripAttribs) { return }
    for (const itemElement of this.$element.querySelectorAll('[grip-item]')) {
      itemElement.removeAttribute('grip-item')
    }
    this._tempGripAttribs = false
  }

  addClone (element) {
    const clone = element.cloneNode(true)
    clone.setAttribute('grip-clone', '')
    clone.removeAttribute('gripping')
    clone.style.visibility = 'visible'
    clone.style.setProperty('width', this.itemBounds.width + 'px')
    clone.style.setProperty('height', this.itemBounds.height + 'px')
    if (this.cloneStyle) { clone.classList.add(this.cloneStyle) }

    this.cloneContainer = document.createElement('div')
    this.cloneContainer.appendChild(clone)

    if (this.$element.querySelectorAll('[gripping]').length > 1) {
      clone.style.setProperty('z-index', `calc(${this.Z_INDEX} + 2)`)
      const clone2 = clone.cloneNode(true)
      clone2.style.setProperty('position', 'absolute')
      clone2.style.setProperty('top', '2px')
      clone2.style.setProperty('left', '2px')
      clone2.style.setProperty('z-index', `calc(${this.Z_INDEX} + 1)`)
      const clone3 = clone2.cloneNode(true)
      clone3.style.setProperty('top', '4px')
      clone3.style.setProperty('left', '4px')
      clone3.style.setProperty('z-index', this.Z_INDEX)
      this.cloneContainer.appendChild(clone2)
      this.cloneContainer.appendChild(clone3)
    }

    const containerStyle = this.cloneContainer.style
    containerStyle.setProperty('position', 'fixed')
    containerStyle.setProperty('z-index', this.Z_INDEX)
    containerStyle.setProperty('top', '0')
    containerStyle.setProperty('left', '0')
    containerStyle.setProperty('pointer-events', 'none')
    containerStyle.setProperty('overflow', 'visible')

    document.body.appendChild(this.cloneContainer)
  }

  moveClone (evt) {
    const { clientX, clientY } = evt
    const x = clientX - this.clickOffsetX
    const y = clientY - this.clickOffsetY
    const transform = `translate3d(${x}px, ${y}px, 0)`
    this.cloneContainer.style.setProperty('transform', transform)
  }

  onMouseMove (evt) {
    if (evt.buttons !== 1) { return this.onStopGrip() }
    if (!this.gripping) {
      if (Math.abs(evt.movementX) + Math.abs(evt.movementY) < 5) { return }
      if (!this.gripElement) { return }
      this.startGrip()
      if (!this.grippedItemElement) { return }
    }
    this.moveClone(evt)

    // If there are no element transforms and there hasn't been
    // a mouseover in a while, then check to see if we are hovering
    // after the end of a section
    if (!this._lastTrailingCheck) { this._lastTrailingCheck = 0 }
    if (!this.lastMouseOver) { this._lastMouseOver = 0 }
    const lastMouseOver = new Date() - this._lastMouseOver
    const lastTrailingCheck = new Date() - this._lastTrailingCheck
    if (this.gripping && lastMouseOver > 250 && lastTrailingCheck > 50) {
      const allLastItems = Array.from(this.$element.querySelectorAll('[grip-item]:last-child'))
      for (let item of allLastItems) {
        const rect = item.getBoundingClientRect()
        if (evt.clientX > rect.right && evt.clientY > rect.top && evt.clientY < rect.bottom) {
          const itemSection = item.closest('[grip-section]')
          const grippedItems = Array.from(this.$element.querySelectorAll('[gripping]'))
          const sameSection = grippedItems.some(e => e.closest('[grip-section]') === itemSection)
          if (sameSection && !grippedItems.some(e => e === item)) {
            this.updateElementTransforms(item, this.grippedItemElement)
          } else if (itemSection && !sameSection) {
            const sectionItems = Array.from(itemSection.querySelectorAll('[grip-item]'))
            sectionItems.forEach(e => this.resetTransform(e))
          }
          this.lastOverItem = item
          break
        }
      }
      this._lastTrailingCheck = new Date()
    }
  }


  /**
   * Shift all the items to reflect their possible new positions.
   */
  onMouseOver (evt) {
    if (!this.cloneContainer) { return }
    const overItem = evt.target.closest('[grip-item]')
    if (!overItem) { return }
    this._lastMouseOver = new Date()
    const isTransformed = overItem.style.getPropertyValue('transform')
    if (isTransformed && this.lastOverItem &&
        (overItem === this.lastOverItem || elementsAreAdjacent(overItem, this.lastOverItem))) {
      this.resetTransform(overItem)
    } else {
      this.updateElementTransforms(overItem, this.grippedItemElement)
      if (isTransformed) { this.resetTransform(overItem) }
    }
    if (!overItem.hasAttribute('grip-ghost')) { this.lastOverItem = overItem }
    if (this.overItem) { this.overItem(overItem) }
  }


  // Reset transform and delete adjacent ghost element if it exists
  resetTransform (element) {
    element.style.setProperty('transform', '')
    const nextElement = element.nextElementSibling
    if (nextElement && nextElement.hasAttribute('grip-ghost')) {

      element.parentNode.removeChild(nextElement)
    }
  }

  /**
   * Move elements that need to be moved.
   *
   * @param {Element} overElement
   * @param {Element} grippedElement
   *
   * v1.3 update:
   * When translating the last page of a section to the right, we need to
   * create a "ghost" element that has the capacity to force the rest of
   * the document to reflow.
   */
  updateElementTransforms (overElement, grippedElement) {
    if (!overElement) { return }
    if (overElement === grippedElement) {
      Array.from(this.$element.querySelectorAll('[grip-item]')).forEach(e => this.resetTransform(e))
      return
    }
    const iterator = this.elementsInsideBoundary(overElement, grippedElement)
    const sameSection = overElement.closest('[grip-section]') === grippedElement.closest('[grip-section]')

    for (let [atElement, nextElement] of iterator) {
      if (!nextElement) {
        if (sameSection) { continue }
        nextElement = atElement.nextElementSibling
        if (!nextElement || !nextElement.hasAttribute('grip-ghost')) {
          const ghost = atElement.cloneNode(true)
          ghost.setAttribute('grip-ghost', '')
          ghost.style.opacity = '0'
          atElement.parentNode.insertBefore(ghost, nextElement)
          nextElement = ghost
        }
      }

      const deltas = this.elementDeltas(atElement, nextElement)
      if (!deltas) { continue }
      const { x, y } = deltas
      this.toggleTransform(atElement, x, y)
    }

    // Reset transforms for elements in other sections
    const overSection = overElement.closest('[grip-section]')
    if (overSection) {
      const overSectionValue = overSection.getAttribute('grip-section')
      Array.from(this.$element.querySelectorAll('[grip-item]'))
        .filter(e => e.closest('[grip-section]').getAttribute('grip-section') !== overSectionValue)
        .forEach(e => this.resetTransform(e))
    }
  }

  * elementsInsideBoundary (overElement, grippedElement) {
    let betweenOverAndGripped = false
    const iterator = this.elementIterator(overElement, grippedElement)

    for (const [atElement, nextElement] of iterator) {
      const isBoundary = atElement === overElement || atElement === grippedElement
      if (isBoundary) { betweenOverAndGripped = !betweenOverAndGripped }

      if (betweenOverAndGripped) {
        if (!atElement.hasAttribute('grip-ghost')) {
          yield [atElement, nextElement]
        }
      } else {
        this.resetTransform(atElement)
      }
    }
  }

  /**
   * Toggle the transform on/off for the given element.
   */
  toggleTransform (element, x, y) {
    const newTranslate = `translate3d(${x}px,${y}px,0)`
    element.style.setProperty('transform', newTranslate)
  }

  /**
   * @yield {[Element, Element]} pairs of the elements, over the entire set
   * of children for this.$element, with the direction depending on whether
   * the element being hovered is before or after the element being gripped.
   *
   * v1.3 [grip-section] update:
   *  - only iterate over elements in the hovered section
   *  - if the gripped element is from a different section, then
   *     iterate as if it were from the very end of the list
   */
  * elementIterator (overElement, grippedElement) {
    const overSection = overElement.closest('[grip-section]')
    const grippedSection = grippedElement.closest('[grip-section]')
    const overPrecedesGrip = overSection !== grippedSection || elementIsBefore(overElement, grippedElement)
    const itemSelector = overSection ? `[grip-section='${overSection.getAttribute('grip-section')}'] [grip-item]` : '[grip-item]'
    const itemList = Array.from(this.$element.querySelectorAll(itemSelector))
    if (!overPrecedesGrip) { itemList.reverse() }
    for (let i=0; i<itemList.length; ++i) {
      yield [itemList[i], itemList[i+1]]
    }
  }

  /**
   * @param {Element} from
   * @param {Element} to
   * @return { x, y } that translate `from` to `to`
   */
  elementDeltas (from, to) {
    if (this.customDelta) { return this.customDelta(from, to) }
    const x = to.offsetLeft - from.offsetLeft
    const y = to.offsetTop - from.offsetTop
    return { x, y }
  }

  onStopGrip () {
    if (!this.gripping) { return }
    const gripping = this.$element.querySelectorAll('[gripping]')
    if (!gripping.length) { return }

    const indexOf = e => e && parseInt(e.getAttribute('grip-item'))
    const fromIndexList = gripping && [...gripping].map(e => indexOf(e))
    let fromIndex = indexOf(this.grippedItemElement)
    let draggingBefore = true

    let toIndex = 0
    const lastSection = this.lastOverItem && this.lastOverItem.closest('[grip-section]')
    const sameSection = Array.from(gripping).some(e => e.closest('[grip-section]') === lastSection)
    if (!lastSection || sameSection) {
      toIndex = indexOf(this.lastOverItem)
      if (toIndex >= 0) {
        const isTransformed = this.lastOverItem && this.lastOverItem.style.getPropertyValue('transform')
        if ((toIndex < fromIndex) ^ (isTransformed !== '')) {
          ++toIndex
          draggingBefore = false
        }
      }
    } else {
      const sectionItems = Array.from(lastSection.querySelectorAll('[grip-item]'))
      toIndex = indexOf(sectionItems.find(e => e.style.transform !== ''))
      if (lastSection && !toIndex) {
        toIndex = indexOf(sectionItems.pop()) + 1
        draggingBefore = false
      }
    }

    if (this.cloneContainer) { this.cloneContainer.remove() }
    let { noReset } = this

    fromIndex = fromIndex || (fromIndexList && fromIndexList.length && fromIndexList[0])
    const validFromIndex = fromIndex >= 0
    const validToIndex = toIndex >= 0 && this.lastOverItem
    const unmoved = toIndex === fromIndex || toIndex === fromIndex + 1

    if (validFromIndex && validToIndex) {
      this.onItemMove({ fromIndex, fromIndexList, toIndex, draggingBefore })
      if (unmoved) { noReset = false }
    } else {
      noReset = false
    }

    if (this.grippedItemElement) {
      this.grippedItemElement.removeAttribute('gripping')
      if (!noReset) { this.grippedItemElement.style.visibility = 'visible' }
    }
    gripping.forEach(e => e.removeAttribute('gripping'))
    this.cloneContainer = this.grippedItemElement = null

    for (const itemElement of this.$element.querySelectorAll('[grip-item]')) {
      if (!noReset) { this.resetTransform(itemElement) }
    }

    this.resetTempMarks()

    this.gripping = false
    this.$element.removeAttribute('grip-area-active')
    this._downTimeout = null
    this.lastOverItem = null
  }

  dispose () {
    super.dispose()
    this.onStopGrip()
  }
}
