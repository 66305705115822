import tko from '@tko/build.reference/dist/build.reference.es6'

export default class DropTarget extends tko.BindingHandler {
  constructor (...args) {
    super(...args)
    if (!this.value) { return }
    const { dragEvt, onDrop } = this.value
    Object.assign(this, {
      dragEvt,
      onDropCB: onDrop,
      leaveTimer: null,
    })
    if (dragEvt) {
      this.addEventListener('dragover', evt => this.onDragOver(evt))
      this.addEventListener('dragenter', evt => this.onDragOver(evt))
      this.addEventListener('dragleave', evt => this.onDragLeave(evt))
    }
    if (onDrop) {
      this.addEventListener('drop', evt => this.onDrop(evt))
    }
  }

  dispose () {
    clearTimeout(this.leaveTimer)
    super.dispose()
  }

  onDragOver (evt) {
    evt.stopPropagation()
    evt.preventDefault()
    clearTimeout(this.leaveTimer)
    this.dragEvt(evt)
  }

  onDragLeave (evt) {
    this.leaveTimer = setTimeout(() => this.dragEvt(null), 50)
  }

  onDrop (evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.onDropCB(evt)
    if (this.dragEvt) { this.dragEvt(null) }
  }
}

