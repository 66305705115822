import tko from '@tko/build.reference/dist/build.reference.es6'
/**
 * @bindingHandler scrollTo
 *
 * When the value is true, scroll into view.
 *
 *    Given a CSS-selector string, scroll to the CSS-selector inside this element.
 *    Otherwise given a truthy value, scroll to this element.
 *
 */
export default class ScrollTo extends tko.BindingHandler {
  constructor (...args) {
    super(...args)
    this.computed(() => {
      const value = this.value
      if (!value) { return }

      if (typeof value === 'object') {
        const { alignToTop, behavior, block, inline, offset  } = value
        if ( behavior || block || inline ) {
          this.$element.scrollIntoView({behavior, block, inline})
        } else {
          this.$element.scrollIntoView(alignToTop)
        }
        if (offset) { window.scrollBy(0, offset) }
      } else {
        const target = typeof value === 'string'
          ? this.$element.querySelector(value)
          : this.$element
        if (target) { target.scrollIntoView() }
        if (typeof value === 'number') {
          window.scrollBy(0, value)
        }
      }

      this.value = null // This reset is very unintuitive behaviour
    })
  }
}
