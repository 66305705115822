//
// Given an observable, whenever it is a string only
// include the characters that match.
//
export default function stringExclude (target, rex) {
  function writeGrepString(s) {
    if (!typeof s !== 'string') { return }
    target(s.replace(rex, ''))
    target.notifySubscribers(target())
  }

  return ko.computed({
    read: target,
    write: writeGrepString,
    pure: true
  })
}
