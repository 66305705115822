/**
 * Eraro is our internal tracking mechanism for errors, used to capture them
 * for our reporting system (in this case, Raven).
 */

//
// Error reporting.
//
export default function eraro (spec, ...extra) {
  if (extra.length) { console.info('Eraro extra', spec, ...extra); }
  if (!typeof spec === 'object') {
    return eraro({msg: spec, level: 'error', args: arguments});
  }
  if (spec.stack) {
    eraro.emit('log', 'Eraro stack', spec.stack);
  }
  if (spec instanceof Error) {
    if (Sentry) { Sentry.captureException(spec); }
    return eraro({msg: spec.message, level: 'error', args: arguments});
  }
  if (spec.statusText) {
    // jQuery XHR
    spec.complete(function () {
      eraro({
        msg: 'XHR ' + spec.statusText + ': ' + this.type + ' ' + this.url,
        level: 'error'
      });
    });
  } else {
    eraro.count++;
    eraro.emit(spec.level, spec.msg, spec.args || '');
  }
}

try {
  eraro.enableTracking = window.location.hostname !== 'localhost';
} catch (e) {
  // node
  eraro.enableTracking = false;
}
eraro.count = 0;
eraro.debug = false;

eraro.emit = function (level, msg, args) {
  if (typeof eraro.pre_emit_hook === 'function') {
    eraro.pre_emit_hook(level, msg, args);
  }
  level = level || 'log';
  console[level].apply(console, [msg].concat(args));
};

eraro.info = function (msg) {
  eraro({msg: msg, level: 'info'});
};

eraro.warn = function (msg) {
  eraro({msg: msg, level: 'warn'});
};

eraro.fail = function (msg) {
  eraro({msg: msg, level: 'error'});
};

eraro.error = function (msg) {
  eraro({msg: msg, level: 'error'});
};

eraro.raise = function (err_param, extra) {
  if (extra) {
    Promise.delay()
      .then(() => console.log('Eraro extra: ', extra) )
  }
  if (typeof err_param === 'string') {
    err_param = new Error(err_param);
  }
  eraro(err_param);
  throw typeof err_param === 'string' ? new Error(err_param) : err_param;
};

