const {_, ko} = global
//
// ForeignKey
// ~~~~~~~~~~
//
// A key to a foreign model. Adds properties to the key as follows:
//
// The target is a foreign key an can be read from or written to as the key.
// It can also be set to 'undefined' (any falsy value will become undefined)
//
const {eraro} = global

export class ForeignKey {
  constructor (keyObs, Model) {
    if (typeof Model !== 'function') {
      eraro.raise(new Error('Param for ForeignKey must be a constructor.'), Model)
    }
    this.key = keyObs
    this.Model = Model
    this.model_class_name = Model.name
    this.is_loading = ko.observable(false)
    this.is_loaded = ko.observable(false)
    this.model = ko.observable()
    this.is_defined = ko.pureComputed(function () {
      return Boolean(ko.unwrap(keyObs))
    })

    // Things to dispose.
    this.subs = []
    this.subs.push(this.is_defined)
    this.subs.push(this.model.subscribe(this.on_model_update, this))
    if (ko.isObservable(keyObs)) {
      this.subs.push(this.key.subscribe(this.on_key_update, this))
    }

    // It may be worth noting that the following may synchronously call both the
    // above subscriptions (key and model) when the model is already loaded for
    // the given key (@key()).
    if (ko.unwrap(keyObs)) {
      this.on_key_update(ko.unwrap(keyObs))
    }
  }

  dispose () {
    var length = this.subs.length
    for (var i = 0; i < length; ++i) {
      this.subs[i].dispose()
    }
  }

  request_for_key () {
    var self = this
    var key = this.key

    function onLoad (model) {
      var key_ = ko.unwrap(self.key)
      var id = ko.unwrap(model.id)
      if (!key_ || id === key_) {
        return model
      }
    }

    if (!this.Model.vm_load) {
      eraro.raise(
        new Error('Model ' + this.Model.name + ' does not have vm_load.'))
    }

    return this.Model.vm_load(ko.unwrap(key))
      .then(onLoad)
      .catch(eraro)
  }

  promise_model () {
    var Model = this.Model
    var key = ko.unwrap(this.key)
    var thisKey = this.key
    // Return model with vm_dependencies loaded
    // The promise is for the key currently assigned.
    if (!key) {
      eraro.raise(new Error('ForeignKey::promise_model ' + Model.name + ': ' +
        'Key has not been set.'))
    }
    this._latest = this.request_for_key()
    return this._latest
      .then(function (model) {
        if (!model) {
          if (key === thisKey()) {
            return Promise.reject(new Error(
              'Model[' + Model.name + ':' + key + '] was not loaded.'))
          }
          // Chain to the next request.
          return this._latest
        }
        return model.vm_loaded
      })
  }

// Return the ForeignKey.
  promise_fk () {
    var self = this
    return this.promise_model()
      .then(function () { return self })
      .catch(eraro)
  }

  on_model_update (model) {
    var key
    if (model) {
      key = ko.unwrap(model.id)
      if (key !== ko.unwrap(this.key)) {
        this.key(key)
      }
      this.is_loaded(true)
      this.is_loading(false)
    } else {
      if (!ko.unwrap(this.key)) {
        // Someone just set the model to a blank. That's ok.
        this.is_loaded(false)
        this.is_loading(false)
        return
      }
      this.key(undefined)
      this.is_loaded(false)
      this.is_loading(true)
    }
  }

  on_key_update (key) {
    if (!key) {
      this.model(undefined)
      this.is_loaded(false)
      this.is_loading(false)
      return
    }

    if (typeof key !== 'string') {
      this.model(undefined)
      this.is_loading(false)
      this.is_loaded(false)
      eraro.raise(new Error('Bad foreignKey'), {
        Model: this.Model,
        key: key
      })
    }

    // if (!this.model() || ko.unwrap(this.model().id) !== key) {
    //   this._load_model(key)
    // }

    // Key is a truthy string.
    const model = this.model.peek()
    if (model && ko.unwrap(model.id) === key) {
      // Already loaded.
      return
    }
    this._load_model(key)
  }

  _load_model (key) {
    if (!key) {
      return
    }

    this.is_loading(true)
    this.is_loaded(false)
    this.promise_model()
      .then(this.model)
      .catch(eraro)
  }

  attr (attr_, ifNotLoaded, ifNotDefined) {
    if (!this.is_defined()) {
      return ifNotDefined
    }
    if (!this.is_loaded()) {
      return ifNotLoaded
    }
    const model = this.model()
    const attr = model[attr]
    return typeof attr === 'function' ? attr.call(model) : attr
  }

  rawAttr (attr_, ifNotLoaded, ifNotDefined) {
    if (!this.is_defined()) {
      return ifNotDefined
    }
    if (!this.is_loaded()) {
      return ifNotLoaded
    }
    return this.model()[attr_]
  }

  debug (name) {
    this.key.subscribe((v) => console.log(name + ' 🔑  ' + v))
    this.model.subscribe(
      function (m) { console.log(name + ' 🔐  ' + m ? m.id() : m) }
    )
  }
}

export default function foreignKey (target, Model) {
  if (ko.isObservableArray(target)) {
    eraro.raise(new Error('Cannot use foreignKey on an observableArray'))
  }
  const model_class_name = Model.name
  if (target.fk instanceof ForeignKey) {
    eraro.warn('Duplicate application of FK ' + model_class_name +
      ' but it is already a ' + target.fk.model_class_name)
    return target
  }
  target.fk = new ForeignKey(target, Model)
  return target
}

foreignKey.ForeignKey = ForeignKey
