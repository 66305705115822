import tko from '@tko/build.reference/dist/build.reference.es6'

/**
 * This is a degenerate text-selection binding.  It selects all the
 * text in the element.
 *
 * It could be extended with parameters for the selection e.g. arrays
 * or multi-arrays.
 */
export default class SelectText extends tko.BindingHandler {
  constructor (...args) {
    super(...args)
    if (!ko.unwrap(this.value)) { return }
    const $e = this.$element
    $e.setSelectionRange(0, $e.value.length)
  }
}
