import tko from '@tko/build.reference/dist/build.reference.es6'

export default class Hover extends tko.BindingHandler {
  constructor (...args) {
    super(...args)
    this.leaveTimer = null
    this.addEventListener('mousemove', evt => this.onMouseMove(evt))
    this.addEventListener('mouseleave', evt => this.onMouseLeave(evt))
  }

  dispose () {
    clearTimeout(this.leaveTimer)
    super.dispose()
  }

  onMouseMove (evt) {
    clearTimeout(this.leaveTimer)
    const [ trect, erect ] = [
      evt.target.getBoundingClientRect(),
      this.$element.getBoundingClientRect()
    ]
    evt.xx = evt.offsetX + (trect.x - erect.x)
    evt.yy = evt.offsetY + (trect.y - erect.y)
    this.value = evt
  }

  onMouseLeave (evt) {
    this.leaveTimer = setTimeout(() => this.value = null, 50)
  }
}

