/**
 * Persist settings.
 *
 * At the moment these are persisted in `localStorage`.
 *
 * These may in future be persisted on a server.  The trouble with that is
 * detecting the authentication manager to use.
 *
 * We could use app.defaultAuthManager, and store the settings in
 *
 *  `/users/{xuid}/settings`
 *
 * The value passed in must be JSON-able.
 *
 * This may cause contention if there are a lot of observables with the
 * same name.
 */
export default function presistentSetting (target, settingKey) {
  settingKey = `mb.setting.${settingKey}`
  target.subscribe(v => localStorage.setItem(settingKey, JSON.stringify(v)))
  target(JSON.parse(localStorage.getItem(settingKey)))
}
