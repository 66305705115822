
import tko from '@tko/build.reference/dist/build.reference.es6'

const noop = () => {}

const MODIFIERS = [
  'ctrl', 'alt', 'shift', 'meta'
]

class MouseHandler extends tko.BindingHandler {
  get eventName () {}

  constructor (...args) {
    super(...args)
    this.addEventListener(this.eventName, evt => this.handle(evt))
  }

  /**
   * Handle:
   *  ko-{mouseup,mousedown,click,dblclick}={handler} or
   *  ko-{mouseup,mousedown,click,dblclick}={{
   *    shift: shiftHandler,
   *    '': defaultHandler,
   *    alt: altHandler
   *  }}
   * @param {mouseEvent} mouseEvt
   */
  handle (mouseEvt) {
    const handlerMap = tko.toJS(this.value) || {}

    if (typeof handlerMap === 'function') {
      return handlerMap(mouseEvt)
    }

    const modifiers = MODIFIERS
      .filter(m => mouseEvt[m + 'Key'])
      .join('+')

    if (handlerMap.debug) {
      console.debug(`[${this.constructor.name} Debug]`,
        modifiers, handlerMap, handlerMap[modifiers])
    }

    return (handlerMap[modifiers] || noop)(mouseEvt)
  }
}

export class Down extends MouseHandler {
  get eventName () { return 'mousedown' }
}

export class Up extends MouseHandler {
  get eventName () { return 'mouseup' }
}

export class Click extends MouseHandler {
  get eventName () { return 'click' }
}

export class DblClick extends MouseHandler {
  get eventName () { return 'dblclick' }
}

export class ownClick extends Click {
  handle (mouseEvt) {
    mouseEvt.stopPropagation()
    mouseEvt.preventDefault()
    super.handle(mouseEvt)
  }
}
