import tko from '@tko/build.reference/dist/build.reference.es6'

/**
 * This is like the regular KO-style, but uses the CSS Typed Object Model:
 *
 *   https://developers.google.com/web/updates/2018/03/cssom
 *
 * The regular KO-style binding has issues in part because it rewrites
 * the `-` character, making it unsuitable for e.g. CSS vars `--some-var`.
 */
export default class StyleMap extends tko.BindingHandler {
  constructor (...args) {
    super(...args)
    const $e = this.$element
    let seen = {}

    const setter = 'attributeStyleMap' in $e
      ? this.typedSet
      : this.legacySet

    this.computed(() => {
      const obj = ko.unwrap(this.value) || {}

      for (const k of Object.keys(seen)) {
        if (k in obj) { continue }
        setter($e, k, undefined)
      }

      seen = obj

      for (const [k, v] of Object.entries(obj)) {
        setter($e, k, tko.unwrap(v))
      }
    })
  }

  typedSet ($e, k, v) {
    if ($e.attributeStyleMap.get(k) !== v) {
      if (v) {
        $e.attributeStyleMap.set(k, v)
      } else {
        $e.attributeStyleMap.delete(k)
      }
    }
  }

  // Fallback for poor support:
  // https://developer.mozilla.org/en-US/docs/Web/API/StylePropertyMap#Browser_compatibility
  legacySet ($e, k, v) {
    if (k.startsWith('--')) {
      // setProperty can throw errors if we set the wrong types,
      // so it's limited to CSS vars here.
      $e.style.setProperty(k, v)
    } else {
      $e.style[k] = v
    }
  }
}
