

/**
 *
 * @param {ko.Observable} obs
 * @param {ko.Subscribable} fn
 */
export function castRead(obs, fn) {
  return ko.computed({ read: () => fn(obs()), write: obs })
}

/**
 *
 * @param {ko.Observable} obs
 * @param {ko.Subscribable} fn
 */
export function castWrite(obs, fn) {
  obs(fn(obs()))  // Cast any existing value
  return ko.computed({ read: obs, write: (v) => obs(fn(v)) })
}
