
import ResizeObserver from './ResizeObserver'
import ScrollObserver from './ScrollObserver'
import viewport from './viewport'
import intersection from './intersection'
import scrollTo from './scrollTo'
import gripArea from './gripArea'
import SelectText from './SelectText'
import StyleMap from './styleMap'
import ThumbnailSectionOverlay from './thumbnail-section-overlay'
import * as keys from './keys'
import * as mouse from './mouse'
import hover from './hover'
import DropTarget from './DropTarget'
import grip from './grip'
import collapse from './collapse'

export default {
  // keys
  keydown: keys.KeyDown,
  keyup: keys.KeyUp,
  keypress: keys.KeyPress,
  'select-text': SelectText,

  // mouse
  click: mouse.Click,
  dblclick: mouse.DblClick,
  mousedown: mouse.Down,
  mouseup: mouse.Up,
  ownClick: mouse.ownClick,
  hover,
  'drop-target': DropTarget,
  grip,

  // window
  'resize-observer': ResizeObserver,

  // scroll
  viewport,
  intersection,
  scrollTo,
  'scroll-observer': ScrollObserver,

  // tools
  'grip-area': gripArea,
  'set-node': (node, fn) => fn(node),
  self: (node, fn) => fn()(node),
  'style-map': StyleMap,
  console: console.log,

  collapse,

  'thumbnail-section-overlay': ThumbnailSectionOverlay,
}

