
import tko from '@tko/build.reference/dist/build.reference.es6'

const scrollCounter = tko.observable(0).extend({ rateLimit: 50 })
let mutex = false

window.addEventListener('scroll', () => {
  if (mutex) { return }
  mutex = true
  requestAnimationFrame(() => {
    mutex = false
    scrollCounter.modify(v => ++v)
  })
})

/**
 * Publish the element.getBoundingClientRect() whenever the window is scrolled
 */
export default class ScrollObserver extends tko.BindingHandler {
  constructor (...args) {
    super(...args)

    const { scrollElement, rect } = this.value || {}
    if (scrollElement) {
      this.onElementScroll = () => { rect(this.$element.getBoundingClientRect()) }
      scrollElement.yet(null).then(e => e.addEventListener('scroll', this.onElementScroll))
      setTimeout(() => this.onElementScroll(), 50)
    } else {
      this.subscribe(scrollCounter, () => this.onScroll())
      this.onScroll()
    }
  }

  onScroll () {
    this.value = this.$element.getBoundingClientRect()
  }

  dispose () {
    if (this.value.scrollElement) {
      this.value.scrollElement().removeEventListener('scroll', this.onElementScroll)
    }
    super.dispose()
  }
}
