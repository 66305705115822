import tko from '@tko/build.reference/dist/build.reference.es6'

/**
 * Thumbnail view section overlay binding.
 *
 * This looks for children with the attribute [section=<name>] and creates
 * and adds color overlay elements to the DOM. The overlays react during drag
 * & drop operations to indicate in which section the page will be dropped.
 *
 * @param {string} overlayClass CSS class name for the overlay elements
 * @param {{top, left, bottom, right}} border Extra amounts, in pixels, to add to element dimensions
 * @param {observable} updateObservable Monitored to indicate when the overlays should be re-rendered
 */
export default class ThumbnailSectionOverlay extends tko.BindingHandler {
  constructor (...args) {
    super(...args)
    if (!this.value) return
    const { overlayClass, updateObservable, border, overItem } = this.value
    Object.assign(this, {
      overlayClass,
      border,
      elementCache: [],
      secondCache: [],
      newElements: [],
      localUpdate: ko.observable()
    })
    updateObservable.subscribe(v => this.localUpdate(v))
    overItem.subscribe(() => this.localUpdate.valueHasMutated())
    this.localUpdate.subscribe(() => this.updateOverlays())
    this.updateOverlays()
  }

  addOverlayElement (section, styles) {
    let overlayElement = this.elementCache.pop()
    if (!overlayElement) {
      overlayElement = document.createElement('div')
      overlayElement.classList.add(this.overlayClass)
      this.newElements.push(overlayElement)
    }
    overlayElement.setAttribute('section-overlay', section)
    for (let p in styles) { overlayElement.style.setProperty(p, `${styles[p]}px`) }
    overlayElement.innerHTML = section
    this.secondCache.push(overlayElement)
  }

  updateOverlays () {
    const pages = this.$element.querySelectorAll(`[section]`)
    if (!pages || !pages.length) { return }
    const { border } = this
    let section = ''
    let styles = {}
    let lastTranslation = null
    let translation = null
    let lastPage = null
    let lastPageHidden = null
    this.secondCache = []
    this.newElements = []
    let gapFound = false
    let pageSection = null

    const singleOverlay = (newsection, page) => {
      const savedStyles = {...styles}
      const savedSection = section
      const left = lastPageHidden ? lastPageHidden.offsetLeft : (translation ? page.offsetLeft : lastPage.offsetLeft)
      const top = lastPageHidden ? lastPageHidden.offsetTop : (translation ? page.offsetTop : lastPage.offsetTop)
      if (newsection) { section = pageSection }
      styles = {
        top: top - border.top,
        left: left - border.left,
        height: page.offsetHeight + border.top + border.bottom,
        width: page.offsetWidth + border.left + border.right
      }
      if (section) { this.addOverlayElement(section, styles) }
      styles = savedStyles
      section = savedSection
    }

    for (let page of pages) {
      if (page.style.getPropertyValue('visibility') === 'hidden') {
        lastPageHidden = page
        continue
      }

      if ( page.offsetHeight < page.offsetWidth ) { setTimeout(() => this.localUpdate.valueHasMutated(), 50); return }
      pageSection = page.getAttribute('section')
      let pageTop = page.offsetTop
      let pageLeft = page.offsetLeft
      let pageWidth = page.offsetWidth

      // Check for element translations applied by the drag & drop system
      const transform = page.style.getPropertyValue('transform')
      if (transform) {
        translation = transform // convert "translate3d(a,b,c)" => { x:a, y:b, z:c }
          .slice(transform.indexOf('(')+1,transform.indexOf(')'))
          .split(',')
          .map(s => parseInt(s))
          .reduce((a, v, i) => ({...a, [['x','y','z'][i]]:v}), {})
        pageLeft += translation.x
        pageTop += translation.y
      }

      // If this page starts a new section
      const newRow = section && (pageTop !== (styles.top + border.top))
      const newOverlay = newRow || (pageSection && (pageSection !== section))
      if (newOverlay) {
        // If drag and drop is hovering between two overlay boundaries we need to
        // extend the overlay across the empty space.
        const isTrShift = Boolean(lastTranslation) ^ Boolean(translation)
        const isPageGap = Boolean(lastPageHidden) ^ isTrShift
        if (isPageGap) {
          const lt = lastTranslation // translation, if any, for the previous page
          const tr = translation // translation, if any, for the current page
          gapFound = true

          // calculate width of the gap
          let delta
          if (isTrShift) {
            delta = (lt && !lt.y && lt.x) || (tr && !tr.y && tr.x)
            if (!delta) {
              delta = lt
                ? pageLeft - lastPage.offsetLeft
                : page.offsetLeft - lastPage.offsetLeft
            }
          } else {
            if (lastPage && (lastPageHidden.offsetTop === lastPage.offsetTop)) {
              delta = lastPageHidden.offsetLeft - lastPage.offsetLeft
            } else {
              delta = page.offsetLeft - lastPageHidden.offsetLeft
            }
          }
          if (delta < 0) { delta = -delta }

          if (!newRow) {
            styles.width += delta
          } else { // if new row
            const hoveringFirstRow = isTrShift
              ? ((lt && !lt.y) || (tr && tr.y))
              : lastPageHidden.offsetTop === lastPage.offsetTop

            if (!pageSection || pageSection === section) { // if not a section boundary
              if (hoveringFirstRow) {
                styles.width += delta
              } else {
                pageLeft -= delta
                pageWidth += delta
              }
            } else { // section boundary at a new row
              if (hoveringFirstRow) {
                  styles.width += delta
              } else { // if mouse at section row
                  singleOverlay('', page)
              }
            }

          }
        }

        // Create overlay
        if (section) { this.addOverlayElement(section, styles) }

        // Init values for next overlay
        if (pageSection) { section = pageSection }
        styles = {
          top: pageTop - border.top,
          left: pageLeft - border.left,
          height: page.offsetHeight + border.top + border.bottom,
          width: pageWidth + border.left + border.right
        }
      } else {
        styles.width = ((pageWidth + pageLeft + border.right) - styles.left)
      }

      lastTranslation = translation
      translation = null
      lastPageHidden = false
      lastPage = page
    }

    if (lastTranslation && !gapFound) { // Hover after the last page
      if (lastTranslation.y) {
        singleOverlay(section, lastPage)
      } else {
        styles.width -= lastTranslation.x
      }
    }
    if (section) { this.addOverlayElement(section, styles) }

    this.newElements.forEach(e => this.$element.appendChild(e))
    this.elementCache.forEach(e => this.$element.removeChild(e))
    this.elementCache = this.secondCache
  }
}
