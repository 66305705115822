
/**
 * Synchronize a value with localStorage.  Automatically stringifies.
 */
export default function localStorage_ (target, storageKey) {
  target.subscribe(v => localStorage.setItem(storageKey, JSON.stringify(v)))
  try {
    const savedValue = localStorage.getItem(storageKey)
    if (savedValue !== null) {
      target(JSON.parse(savedValue))
    }
  } catch (err) {}
}
