
export { default as foreignKey } from './foreignKeyExtender'
export { default as foreignKeyMap } from './foreignKeyMapExtender'
export { default as stringExclude } from './stringExclude'
export { default as localStorage } from './localStorage'
export { default as persistentSetting } from './persistentSetting'
export { default as historyState } from './historyState'
export { default as arrayProperties } from './arrayProperties'
export { default as lockWhile } from './lockWhile'

/**
 * inAnimationFrame only writes to the `target` at the next animation frame.
 */
export function inAnimationFrame (target) {
  let i = 0
  let mutex
  return ko.computed({
    pure: true,
    read: target,
    write: v => {
      const mutexPointer = mutex = Symbol(`inAminationFrame Mutex ${i++}`)
      requestAnimationFrame(() => {
        if (mutex === mutexPointer) { target(v) }
      })
    }
  })
}

export function readInAnimationFrame (target) {
  const clone = ko.observable()
  requestAnimationFrame(() => clone(target()))
  target.subscribe(v => requestAnimationFrame(() => clone(v)))
  return clone
}

/**
 * Proxy the given properties from the underlying observable/computed.
 *
 * Usage:
 *
 *    ko.computed(() => Array).extend({ proxy: 'length' })
 *    ko.computed(() => Map).extend({ proxy: ['size', 'get', 'set'] })
 *
 * @param {ko.Observable} target
 * @param {Array.<string>} properties
 */
export function proxy (target, properties) {
  if (!Array.isArray(properties)) { properties = [properties] }
  const mapFn = p => ({ [p]: {
    get () {
      const targetValue = target()
      const targetProp = targetValue[p]
      return typeof targetProp === 'function'
        ? targetProp.bind(targetValue) : targetProp
    }
  } })
  const propFns = Object.assign({}, ...properties.map(mapFn))
  Object.defineProperties(target, propFns)
}

export { castWrite, castRead } from './cast'

export function strict (target, fn) {
  return ko.pureComputed({
    read: target,
    write: v => {
      try { target(fn(v)) } catch (err) { target(null) }
    }
  })
}

export function toJSON (target, param) {
  switch (param) {
    case true:
      target.toJSON = () => target()
      break
    case null:
    case undefined:
      target.toJSON = () => param
      break
  }
  return target
}
