import tko from '@tko/build.reference/dist/build.reference.es6'

/**
 * @bindingHandler viewport
 *
 * Use `IntersectionObserver` to determine when the given node is
 * in a viewport.
 *
 * Usage:
 *
 *    ko-intersection={callbackFunction}
 *
 * where the `callbackFunction` is called anytime there is a
 * viewport event.
 *
 *    ko-intersection={fn(boolean)|observable<boolean>}
 *    ko-intersection={{in: inFn, out: outFn, callback: anyFn, options, publish }}
 *
 * where `inFn` is called when the node is in the viewport,
 * the `outFn` when the node leaves the viewport, and
 * the `callback` on any viewport event.
 * `options` may specify any of the observer options as described at:
 * https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver
 * `publish` as below on the constructor
 * The callbacks are called with the `IntersectionObserverEntry`.
 */
export default class Intersect extends tko.BindingHandler {
  /**
   * @param {object} params are passed to `IntersectionObserver`
   * @param {string} params.publish one of the options in `publishValue`
   */
  constructor (params) {
    super(params)

    const args = this.value || {}
    const root = args.root instanceof Node ? args.root
      : typeof args.root === 'string'
        ? document.querySelector(args.root) : null

    const options = Object.assign({ root }, { ...args.options })
    Object.assign(this, {
      io: new IntersectionObserver(
        entries => this.onIntersect(entries), options),
      publish: params.publish || 'bool',
    })

    this.io.observe(this.$element)
  }

  publishValue (entry) {
    switch (this.publish) {
      case `bool`: return entry.isIntersecting
      case `entry`: return entry
      case `node`: return this.$element
      case `context`: return this.$context
      case `data`: return this.$data
      default:
        throw new Error(
          `<=> ko-intersect Bad publish value: ${this.publish}`)
    }
  }

  onIntersect (entries) {
    if (typeof this.value === 'object') {
      for (const entry of entries) {
        const value = this.publishValue(entry)
        if (entry.isIntersecting && this.value.in) {
          this.value.in(value)
        }
        if (!entry.isIntersecting && this.value.out) {
          this.value.out(value)
        }
        if (this.value.value) {
          this.value.value(value)
        }
      }
    } else if (typeof this.value === 'function') {
      for (const entry of entries) {
        this.value(this.publishValue(entry))
      }
    } else {
      for (const entry of entries) {
        this.value = this.publishValue(entry)
      }
    }
  }

  dispose (...args) {
    super.dispose(...args)
    this.io.unobserve(this.$element)
  }
}
