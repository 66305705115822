
import tko from '@tko/build.reference/dist/build.reference.es6'

const resizeCounter = tko.observable(0)
let mutex = false

window.addEventListener('resize', () => {
  if (mutex) { return }
  mutex = true
  requestAnimationFrame(() => {
    mutex = false
    resizeCounter.modify(v => ++v)
  })
})

/**
 * Publish the `getBoundingClientRect` value for the element anytime the
 * window is resized.  Poor man's ResizeObserver.
 *
 * Usage:
 *  <element ko-resize-observer={observable} />
 *
 * Where `observable` will be updated on binding and anytime there is a
 * window resize.
 *
 * Even thought this is throttled with `requestAnimationFrame`, these events
 * can still be frequent and for expensive ops it can be worthwhile to
 * further rate-limit the observable. (Noting that that can cause jank when
 * there are latent DOM operations on the resize).
 */
export default class ResizeObserver extends tko.BindingHandler {
  constructor (...args) {
    super(...args)
    this.subscribe(resizeCounter, () => this.onResize())
    this.onResize()
  }

  onResize () {
    this.value = this.$element.getBoundingClientRect()
  }
}
