
import jssLib from 'jss'
import preset from 'jss-preset-default'
import FileSaver from 'file-saver'
import tko from '@tko/build.reference/dist/build.reference.es6'
import bindings from 'ko.bindingHandlers'
import pako from 'pako'
import * as extenders from 'ko.extenders'
import * as Sentry from '@sentry/browser'

import eraro from 'utils/eraro'

Object.assign(window, {
  eraro,
  FileSaver,
  jssLib,
  ko: tko,
  pako,
  tko,
  Sentry,
})

// FIXME: Disable the AttrProvider b/c it conflicts with the
//        jsx provider i.e. ko-* in JSX will be made an attribute
//        and interpreted by the attr provider as ko-*.
// tko.bindingProvider.providers.splice(5, 1)
// tko.bindingProvider.nodeTypeMap[1].splice(4, 1)

jssLib.setup(preset())

Object.assign(tko.extenders, extenders)
tko.bindingHandlers.set(bindings)
// Add array properties to all observableArrays
extenders.arrayProperties(ko.observableArray.fn)

// if (location.hostname !== 'localhost') {
//   eraro.startTracking('https://b07cac819eae4757a00b2cdf2b4599c3@app.getsentry.com/89705')
// }
