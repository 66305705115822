/**
 * HTML5 History State
 * --
 *
 * When an extended observable changes, push a new history state.
 *
 * When the user hits `back`, pop the state.
 *
 * See:
 *  - https://developer.mozilla.org/en-US/docs/Web/API/History
 *  - https://css-tricks.com/using-the-html5-history-api/
 *  - https://zinoui.com/blog/single-page-apps-html5-pushstate
 */

let atIndex = 0
let poppingMutex = false
const historyStack = []

/**
 * Extender: historyState.
 */
export default function historyState (target, makeHistoryUrl) {
  target.subscribe(async value => {
    if (poppingMutex) { return }
    const currentState = historyStack[atIndex] || {}
    if (currentState.target === target && currentState.value === value) {
      return
    }

    const url = makeHistoryUrl(value)

    if (url === null) { return }
    if (!url) { throw new Error(`historyState function must return a "url"`) }
    if (url instanceof Promise) {
      const state = { target, value }
      const stateIndex = atIndex
      historyStack[stateIndex] = state
      atIndex++
      history.pushState({ index: historyStack.length - 1 }, '', location.hash)
      const resolved = await url
      if (history.state.index === stateIndex) {
        history.replaceState(history.state, '', resolved)
      }
    } else {
      historyStack[atIndex] = { target, value }
      atIndex++
      history.pushState({ index: historyStack.length - 1 }, '', url)
    }
  })
  return target
}

window.addEventListener('popstate', evt => {
  if (evt.state) {
    atIndex = evt.state.index
    const historyState = historyStack[atIndex]
    if (!historyState) { return }
    poppingMutex = true
    try {
      historyState.target(historyState.value)
    } finally {
      poppingMutex = false
    }
  }
})
